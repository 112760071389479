var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"fields":_vm.fields,"items":_vm.model,"empty-text":"Não existe ações para este processo"},scopedSlots:_vm._u([{key:"cell(acao)",fn:function(ref){
var item = ref.item;
return [_c('GenericSelect',{key:"id_acao",ref:"acao",attrs:{"route":_vm.routeAcoes,"labelKey":"descricao"},model:{value:(item.id_acao),callback:function ($$v) {_vm.$set(item, "id_acao", $$v)},expression:"item.id_acao"}})]}},{key:"cell(tipo)",fn:function(ref){
var item = ref.item;
return [_c('GenericSelect',{ref:"tipo",attrs:{"route":_vm.routeTipos,"labelKey":"label"},model:{value:(item.tipo),callback:function ($$v) {_vm.$set(item, "tipo", $$v)},expression:"item.tipo"}})]}},{key:"cell(validacoes)",fn:function(ref){
var item = ref.item;
return [_c('GenericMultipleSelect',{attrs:{"labelKey":"chave","route":_vm.routeValidacaoAcao,"value":item.validacoes.map(function (el) { return ({ value: el.id_validacao_acao, label: el.descricao }); })},on:{"input":function (state){ return _vm.changeValidacoes(item, state); }}})]}},{key:"cell(terminoAutomatico)",fn:function(ref){
var item = ref.item;
return [_c('b-checkbox',{model:{value:(item.termino_automatico),callback:function ($$v) {_vm.$set(item, "termino_automatico", $$v)},expression:"item.termino_automatico"}})]}},{key:"cell(excluir)",fn:function(ref){
var index = ref.index;
return [_c('b-button',{staticClass:"btn btn-sm text--black",attrs:{"variant":"outline-dark"},on:{"click":function($event){return _vm.removeAcao(index)}}},[_c('trash-2-icon',{staticClass:"w20px"})],1)]}}])}),_c('Button',{attrs:{"text":"Cancelar","variant":"grayscale"},on:{"click":_vm.cancel}}),_c('Button',{attrs:{"text":"Salvar"},on:{"click":_vm.save}}),_c('Button',{attrs:{"text":"Adicionar"},on:{"click":_vm.add}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }